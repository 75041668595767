<template>
  <div class="">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input
          class="select-width"
          size="medium"
          v-model="name"
          placeholder="姓名" />

        <el-select
          v-btn-permission="'008006007001'"
          class="select-width"
          size="medium"
          v-model="isolationPointId"
          placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>

        <el-date-picker
          v-model="pickerTime"
          size="medium"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="resetUserInfo">重置</el-button>
      </div>
    </div>

    <TableModel
      :is-show-select="true"
      :is-show-tabs="false"
      :loading="loading"
      :table-data="tableData"
      table-size="medium">
      <el-table-column prop="crtUserName" label="操作人" />
      <el-table-column prop="roleName" label="用户角色" />
      <el-table-column prop="isolationPointName" label="隔离点" />
      <el-table-column prop="operationModule" label="操作板块" />
      <el-table-column prop="operationContent" label="操作内容" />
      <el-table-column prop="crtTime" label="操作时间" />
    </TableModel>

    <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import TableModel from '../../components/TableModel';
import { operationRecordListApi } from '../../api/SystemManagement/index';
import { mapState } from 'vuex';

export default {
  name: "OperationLog",
  components: { TableModel },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      tableData: [], //表单数据
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      name: "",
      isolationPointId: "",
      pickerTime: []
    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,  // 隔离点列表数据
    })
  },

  created() {
    this.operationRecordList();
  },

  methods: {
    operationRecordList() {
      let params = {
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let { name, pickerTime, isolationPointId } = this;
      if (name) params.name = name;
      if (isolationPointId) params.isolationPointId = isolationPointId;
      if (pickerTime && pickerTime.length == 2) {
        params.startTime = pickerTime[0] + " 00:00:00";
        params.endTime = pickerTime[1] + " 23:59:59";
      }

      operationRecordListApi({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.tableData = data.list;
          this.total = data.totalRow;
        } else {
          this.$message.error(msg)
        }
      })
    },

    queryUserInfo() {
      this.operationRecordList();
    },

    resetUserInfo() {
      this.name = "";
      this.isolationPointId = "";
      this.pickerTime = [];
      this.operationRecordList();
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.operationRecordList();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.operationRecordList();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>

<style scoped>
.el-table >>> td {
  padding: 6px 0;
}
</style>
